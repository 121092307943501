<template>
    <header class="header-block">
      <!--header page-->
      <div class="header-page">
         <div class="header-col">
            <a href="#" class="btn btn-white btn-burger" v-on:click="openMenu" aria-label="open menu">
               <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M1 1H19M1 7H19M1 13H19" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </a>
         </div>
         <div class="header-col">
               <div class="header-logo">
               <!-- <router-link to="/" routeTo="/" @click.native="updateDataSwitch">
                  <img src="images/Logo-harmony-saint-ouen.svg"' alt="Logo-harmony-saint-ouen" width="140" height="161">
               </router-link> -->
               </div>
         </div>
         <div class="header-col header-right">
            <div class="switch-block">
               <label class="link-switch link-harmony" active-class="active" for="harmony">Harmony</label>
               <input type="checkbox" id="switch" :lastUrl="val" :idUrl="val2" class="switch-checkbox" v-on:click="switchUrl">
               <label for="switch" class="switch_label">
                  <span class="onf_btn"></span>
               </label>
               <label class="link-switch link-rhapsody" for="switch">Rhapsody</label>
            </div>
         </div>
      </div>
      <!--header page-->

      <!--Menu-->
      <div class="menu" :class="{'show': showMenu, 'animateBrush': animateCloseMenu}" id="menuPage" @wheel="preventBodyWheel">
         <a href="#" class="btn btn-white btn-close" v-on:click="closeMenu" aria-label="close menu">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
         </a>
         <div class="menu-block-container">
            <div class="menu-bg-grid">
               <div class="menu-grid-left"><img class="bg-menu" :src='this.$pathprod+"images/menu/bg-menu.png"' alt="bg-menu" rel="preload"></div>
            </div>

            <div class="menu-block">
               <div class="menu-left">
               <div class="menu-left-content">
                  <!--Menu list-->
                  <ul class="menu-list">
                     <li class="menu-list-item">
                        <router-link :to="{ name: 'Home', params: { lang: this.$i18n.locale }}" class="link-menu" @click.native="closeMenu" data-section="1" data-page="accueil">{{$t('headerMenu')['0']}}</router-link>
                     </li>
                     <li class="menu-list-item">
                        <span>
                           <router-link :to="{ name: 'SaintOuen', params: { lang: this.$i18n.locale }}" class="link-menu" @click.native="closeMenu">{{$t('headerMenu')['1']}}</router-link>
                        </span>
                        <ul class="menu-sublist">
                           <li class="sublist-item">
                              <router-link :to="{ name: 'SaintOuen', hash: '#suivre-ligne', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['2']}}</router-link>
                           </li>
                           <li class="sublist-item">
                              <router-link :to="{ name: 'SaintOuen', hash: '#mobilite-douce', params: { lang: this.$i18n.locale } }" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['3']}}</router-link>
                           </li>
                           <li class="sublist-item">
                              <router-link :to="{ name: 'SaintOuen', hash: '#saint-ouen-vie', params: { lang: this.$i18n.locale } }" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['4']}}&nbsp;!</router-link>
                           </li>
                           <li class="sublist-item">
                              <router-link :to="{ name: 'SaintOuen', hash: '#ca-bouge', params: { lang: this.$i18n.locale } }" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['5']}}&nbsp;!</router-link>
                           </li>
                           <li class="sublist-item">
                              <router-link :to="{ name: 'SaintOuen', hash: '#envole-tertiaire', params: { lang: this.$i18n.locale } }" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['6']}}</router-link>
                           </li>
                        </ul>
                     </li>
                     <li class="menu-list-item">
                        <router-link :to="{ name: 'EntrerHarmony', params: { lang: this.$i18n.locale }}" class="link-menu" @click.native="closeMenu">{{$t('headerMenu')['7']}}</router-link>
                     <ul class="menu-sublist">
                        <li class="sublist-item">
                           <router-link :to="{ name: 'EntrerHarmony', hash: '#geste-precis', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['8']}}</router-link>
                        </li>
                        <li class="sublist-item">
                           <router-link :to="{ name: 'EntrerHarmony', hash: '#dynamique-services', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['9']}}</router-link>
                        </li>
                        <li class="sublist-item">
                           <router-link :to="{ name: 'EntrerHarmony', hash: '#vivre-harmony', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['10']}}</router-link>
                        </li>
                        <li class="sublist-item" >
                           <router-link :to="{ name: 'EntrerHarmony', hash: '#roof-top', params: { lang: this.$i18n.locale }}" class="sublink-menu" @click.native="closeMenu">{{$t('headerMenu')['11']}}</router-link> 
                        </li>
                     </ul>
                     </li>
                     <li class="menu-list-item">
                        <router-link :to="{name: 'TraiteHarmony', params: { lang: this.$i18n.locale }}" class="link-menu" @click.native="closeMenu">{{$t('headerMenu')['12']}}</router-link></li>
                     <li class="menu-list-item">
                        <router-link :to="{name: 'Contact', params: { lang: this.$i18n.locale }}" class="link-menu" @click.native="closeMenu">{{$t('headerMenu')['13']}}</router-link>
                     </li>
                     <li class="menu-list-item">
                        <router-link :to="{name: 'Telechargement', params: { lang: this.$i18n.locale }}" class="link-menu" @click.native="closeMenu">{{$t('headerMenu')['14']}}</router-link>
                     </li>

                     <!--**********************
                           Switch langue
                      ************************-->
                     <li class="menu-list-item switch_lang">
                        <a href="javacript;;" class="link-menu btn_switch_lang separator_barre">
                           <input type="radio" id="french" name="lang-selector" class="d-none" value="fr" v-model="lang" @click="switch_lang()">
                           <label for="french" class="switch_fr menu-lang">FR</label>
                        </a>
                        <a href="javacript;;" class="link-menu btn_switch_lang">
                           <input type="radio" id="english" name="lang-selector" class="d-none" value="en" v-model="lang" @click="switch_lang()">
                           <label for="english" class="switch_uk menu-lang">UK</label>
                        </a>
                        <input type="text" id="path_lang" class="d-none" :value="lang">
                     </li>
                  </ul>
                  <!--Menu list-->

                  <!--logo partner mobile-->
                  <div class="list-partner">
                     <a class="list-item" href="https://fr.linkedin.com/company/cdc-investissement-immobilier" target="_blank"><img :src='this.$pathprod+"images/logo/logo-CDC-Invest-Immo.svg"' alt="CDC-Invest-Immo" width="121" height="20"></a>
                     <a class="list-item" href="https://www.allianz.fr/" target="_blank"><img :src='this.$pathprod+"images/logo/logo-allianz.svg"' alt="Allianz" width="108" height="29"></a>
                     <a class="list-item" href="https://mabanque.bnpparibas/" target="_blank"><img :src='this.$pathprod+"images/logo/logo-bnp-paribas.svg"' alt="BNP paribas" width="119" height="26"></a>
                     <a class="list-item" href="https://www.emerige.com/" target="_blank"><img :src='this.$pathprod+"images/logo/logo-emerige.svg"' alt="Emerige" width="70" height="39"></a>
                     <router-link :to="{name: 'MentionsLegales', params: { lang: this.$i18n.locale }}" class="lien-legale" @click.native="closeMenu">{{$t('headerMenu')['15']}}</router-link>
                  </div>
                  <!--/logo partner mobile-->

               </div>
               </div>
            </div>
         </div>
         <div style="preload-video"><VideoSaintOuen/></div>
         <div style="preload-video"><VideoEntrerHarmony/></div>
         <div style="preload-video"><VideoTelechargement/></div>
      </div>
      <!--Menu-->
    </header>
</template>

<script>
export default {
  name: 'Header',
  components:{
    VideoSaintOuen: () => import('@/components/video/VideoSaintOuen.vue'),
    VideoEntrerHarmony: () => import('@/components/video/VideoEntrerHarmony.vue'),
    VideoTelechargement: () => import('@/components/video/VideoTelechargement.vue'),
  },
  props: {
    msg: String,
  },
  data:() => ({
      showMenu: false,
      showAnimationLottie: false,
      showBrushRight: false,
      animateCloseMenu: false,
      randomNumber:'0',
      val: '/',
      val2: '0',
  }),

   methods: {
      openMenu(e){
         e.preventDefault();   
         document.querySelector('.menu').classList.remove('animateBrush');    
         document.querySelector('.menu').classList.add('show');
         // this.animateCloseMenu = false;
         // this.showMenu = true;
         setTimeout(function(){
            document.body.classList.add('open-menu');
            document.querySelector('.menu-grid-left').classList.add('brushed');
         },600);

         setTimeout(function(){
            document.querySelector('.menu-list').classList.add('showM');
         },1300)
      },

      playEnteteVideo(enteteVideo){
         var video = document.querySelector(enteteVideo + " .bg-hero")

         if(video){
            video.play()           
            let inter = setInterval(() =>{
               if(video.paused && video.ended == false ){
                  video.play()
               }
            }, 500);

            video.addEventListener("ended", function(){
               clearInterval(inter)
            })    
         }
      },

      closeMenu(e){
         e.preventDefault();
            document.querySelector('.menu').classList.add('animateBrush');    
            document.querySelector('.menu').classList.add('show');
         setTimeout(function(){
            document.body.classList.remove('open-menu');
            document.querySelector('.menu-list').classList.remove('showM');
            document.querySelector('.menu-grid-left').classList.remove('brushed');
         }.bind(this),300)

         setTimeout(function(){   
            document.querySelector('.menu').classList.remove('show');
         }.bind(this), 1000);

         //-------------------------------------------------------

         this.playEnteteVideo(".section-first")
         this.playEnteteVideo("#saintOuen-1")
         this.playEnteteVideo(".tl-ctnLeft")
      },

      scrollToSection(e, element){
         e.preventDefault();
         var container = this.$el.querySelector(element);
         container.scrollTop = container.scrollHeight;
         container.scrollIntoView({behavior: 'smooth'});
      },

      switchUrl(){
         //*************************** */
         // VARIABLE D'ENVIRONNEMENT
         //*************************** */
         var current_lang = document.getElementById("path_lang").value
         var pathprod = this.$pathprod + current_lang + "/"
      
         var btn_switch = document.getElementById("switch");
         var envUrl = window.location.href
         var origin_url = window.location.origin
         var ancre_Url = window.location.hash
         var urlPath_EH = pathprod + "composition-inclusive"
         var switch_url = origin_url.replaceAll('harmony', 'rhapsody');
         
         if(btn_switch.checked){
            //Home Entry harmony
            if(envUrl == origin_url + pathprod + "entry-harmony"){
               window.location.href = switch_url + urlPath_EH
            }else
            //Home Saint-ouen
            if(!ancre_Url || envUrl == origin_url + pathprod + "saint-ouen" + ancre_Url){
               window.location.href = envUrl.replaceAll('harmony', 'rhapsody');
            }else{
               switch (ancre_Url) {
                  //------------------------------
                  //Ancrage Entrer harmony
                  //------------------------------
                  case "#geste-precis":
                     window.location.href = switch_url + urlPath_EH +"#esquisse-projet"
                     break;

                  case "#dynamique-services":
                     window.location.href = switch_url + urlPath_EH +"#espace-rassembleurs"
                     break;

                  case "#vivre-harmony":
                     window.location.href = switch_url + urlPath_EH +"#pollinisateur"
                     break;

                  case "#roof-top":
                     window.location.href = switch_url + urlPath_EH +"#roof-top"
                     break;

                  default:
                     window.location.href = switch_url + urlPath_EH
                     break;
               }
            }
         }
      },

      updateDataSwitch(e){
         let valHref = e.target.parentNode.getAttribute('routeTo');
         let valHrefID = e.target.parentNode.getAttribute('data-same-link');
         this.val = valHref;
         this.val2 = valHrefID;
      },
         
      getRandomInt(max) {
         return Math.floor(Math.random() * max);
      },

      switch_lang(){
         var french = document.getElementById("french").checked

         if(french){
            document.querySelector(".switch_fr").classList.add("currentLang")
            document.querySelector(".switch_uk").classList.remove("currentLang")
         }else{
            document.querySelector(".switch_fr").classList.remove("currentLang")
            document.querySelector(".switch_uk").classList.add("currentLang")
         }
      },

      preventBodyWheel(e){
         if(document.body.classList.contains('open-menu')){
            e.stopPropagation();
         }
      }
   },

  mounted(){
      //Gestion switch button checked to harmony
      window.addEventListener("load", function(){
          var siteName = window.location.href;
          var switchBtn = document.getElementById("switch")
          if(siteName.includes('harmony')){
            switchBtn.checked = false;
          }
      })
      //Check langue switch onLoad page
      switch (this.$i18n.locale) {
         case "fr":
               document.querySelector(".switch_fr").classList.add("currentLang")
               document.querySelector(".switch_uk").classList.remove("currentLang")
         
            break;
         case "en":
               document.querySelector(".switch_fr").classList.remove("currentLang")
               document.querySelector(".switch_uk").classList.add("currentLang")
            break;
         default:
            break;
      }
  },

   computed: {
      lang: {
         get: function() {
            return this.$store.state.locale
         },
         set: function(newVal) {
            this.$store.dispatch('changeLocale', newVal)
         }
      }
   },
}
</script>